<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                @submit.native.prevent
            >
                <el-form-item label="课程名称" prop="courseName">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.courseName"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="primary" plain @click="addReport"
                    >新增</el-button
                >
                <el-button type="danger" plain @click="allDeleteClick"
                    >删除</el-button
                >
            </div>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column
                    prop="id"
                    width="55"
                    label="#"
                ></el-table-column>
                <el-table-column prop="courseName" label="课程名称">
                </el-table-column>
                <el-table-column prop="createTime" label="时间">
                </el-table-column>
                <el-table-column prop="status" label="是否启用">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status | isYes }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="170">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editClick(scope.row)"
                            >编辑</el-button
                        >
                        <el-button type="text" @click="deleteClick(scope.row)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { getTrainCourseListApi, getTrainCourseDeleteApi } from '@/api/training'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            typeList: [
                {
                    label: '外贸调研',
                    value: '外贸调研',
                },
                {
                    label: '商务部标准版本',
                    value: '商务部标准版本',
                },
                {
                    label: '山东兼容版',
                    value: '山东兼容版',
                },
            ],
            formInline: {
                name: '',
            },
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.getTrainCourseListFun()
    },
    methods: {
        // 获取列表
        async getTrainCourseListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: pageSize ? pageSize : 10,
                draw: 1,
                orderStr: 'id desc',
            }
            var p = Object.assign(params, this.formInline)
            var res = await getTrainCourseListApi(p)
            this.pageparm.total = res.recordsTotal
            this.tableData = res.data
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            this.getTrainCourseListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.getTrainCourseListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            // this.formInline.page = parm.currentPage
            // this.formInline.limit = parm.pageSize
            this.getTrainCourseListFun(parm.currentPage, parm.pageSize)
        },
        // 新增模板
        addReport() {
            this.$router.push({
                path: '/trainingCourse/add',
            })
        },
        // 编辑模板
        editClick(row) {
            console.log('row', row)
            this.$router.push({
                path: '/trainingCourse/add',
                query: {
                    id: row.id,
                },
            })
        },
        // 删除多个
        allDeleteClick() {
            var ids = this.multipleSelection.map((item) => item.id)
            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        console.log('action', action)
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await getTrainCourseDeleteApi(params)
                            console.log('删除结果', res)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.getTrainCourseListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
        // 删除
        deleteClick(item) {
            var ids = []
            ids.push(item.id)
            this.$alert(
                '这确定要删除' + item.courseName + '吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await getTrainCourseDeleteApi(params)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                            this.getTrainCourseListFun()
                        }
                    },
                }
            )
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
